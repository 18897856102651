import React from "react";
import styled from "styled-components";
import process from "../img/process.png";
import reg from "../img/reg.png";
import ver from "../img/ver.png";
import pay from "../img/pay.png";
import track from "../img/track.png";

const Process = () =>{

    return(
        <Container>
            <div>
                <ul>
                    <li>
                        <h1>
                            Our Process 
                        </h1>
                    </li>
                    <div>
                        <div>
                            <li>
                                <p>
                                <span>Registration:</span> Contact Veles with the details of your desired transaction, specifying the consumer electronics you want to source, such as mobile phones, tablets, laptops, or gaming consoles, and all pertinent details with respect to the parties involved. At this stage, we will discuss your payment terms, goods transfer deadlines, and the fees for our services.
                                </p>
                            </li>
                        </div>
                        <div><img src={reg} alt="icon"/></div>
                    </div> 
                    <div>
                        <div>
                            <li>
                                <p>
                                <span>Verification:</span> Our team will carefully review your application and verify your account, ensuring compliance with international trade regulations and financial laws. Before your transaction begins, we will connect you with our experts in consumer electronics logistics and allocate warehouse and shipping resources for your goods. We also use a comprehensive database of suppliers and buyers in the electronics industry to assess your trade, taking into account past performance and our internal ratings.
                                </p>
                            </li>
                        </div>
                        <div><img src={ver} alt="icon"/></div>
                    </div>
                    <div>
                        <div>
                            <li>
                                <p>
                                <span>Payment:</span> Once the inspection and logistics for the consumer electronics are finalised, the buyer will transfer payment to an intermediary account managed by Veles. We securely hold the funds while the goods are shipped. Once all items are delivered to the agreed-upon location, verified by a Veles specialist, and approved by the buyer, the payment is released from the intermediary account to the seller.
                                </p>
                            </li>
                        </div>
                        <div><img src={pay} alt="icon"/></div>
                    </div>
                    <div>
                        <div>
                            <li>
                                <p>
                                    <span>Track and Manage:</span> Effortlessly monitor your transaction history, manage suppliers, and track the payment and shipment of your consumer electronics by staying in contact with our dedicated trade specialists who are always available to ensure everything runs smoothly. Plus, we handle all international transactions, ensuring payments are processed in the correct currency, no matter where your trade takes place.
                                </p>
                            </li>
                        </div>
                        <div><img src={track} alt="icon"/></div>

                    </div>
                </ul>
                
            </div>
            <div>
                <img src={process} alt="process" />                
            </div>
            
        </Container>
    )

}

const Container = styled.div`
    display:flex;
    justify-content: space-between;
    padding: 5rem 10rem;
    >div{
        width:45%;
            ul{
                h1{
                    margin-bottom:1.5rem;
                }
                div{
                    display:flex;
                    justify-content: space-between;
                    div{ 
                        margin:auto;
                        img{
                            width:100px;
                            height:100px;
                            margin-left:1rem;
                        }
                    }
                    margin-bottom:2rem;
                }
            }

        img{
            width:100%;
        }
    }
    @media(max-width:1100px){
        flex-direction:column;
            >div{
                width:100%;
                >img{
                    margin-top:3rem;
                }
            }
     }
     @media(max-width:860px){
        >div{
                ul{
                    div{
                        div{ 
                          
                            img{
                                width:70px;
                                height:70px;
                            }
                        }
                    }
                }
        }
    }
     @media(max-width:680px){
 
        >div{
            width:100%;
            ul{
                div{      
                    flex-direction:column;           
                    div{ 

                        img{
                            margin-left:0rem;
                            margin-top:1rem;
                        }
                    }
                }
            }
        }
        padding: 5rem 5rem;
    }
     @media(max-width:400px){
        padding: 5rem 1.5rem;
     }

`;

export default Process;
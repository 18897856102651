import React from "react";
import styled from "styled-components";
import contact from "../img/contact.png";

const Contact = () =>{

    return(
        <Container>
            <div>
                <ul>
                    <li>
                        <h1>
                            Contact
                        </h1>
                    </li>
                    <li>
                        <p>
                        To start sourcing cutting-edge consumer electronics today, contact us by email or by phone using the following information:

                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Email:</b> veleslimited@gmail.com
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Office Phone:</b>  (+371) 660 90 767
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Mobile:</b> (+44) 7779 278 530
                        </p>
                    </li>
                    <li>
                        <p>
                            We can’t wait to hear from you. 
                        </p>
                    </li>
                </ul>
                
            </div>
            <div>
                <img src={contact} alt="contact" />                
            </div>
            
        </Container>
    )

}

const Container = styled.div`
    display:flex;
    justify-content: space-between;
    padding: 5rem 10rem;
    div{
        width:45%;
        ul{
            li{
                margin-bottom:2rem;
             
            }
        }
        img{
            width:100%;
        }
    }
    @media(max-width:1124px){
        flex-direction:column;
            div{
                width:100%;
                img{
                    margin-top:3rem;
                }
            }
     }
     @media(max-width:624px){
        padding: 5rem 5rem;
     }
     @media(max-width:400px){
        padding: 5rem 1.5rem;
     }

`;

export default Contact;
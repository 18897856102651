import React from "react";
import styled from "styled-components";
import mainpage from "../img/mainpage.png";

const MainPage = () =>{

    return(
        <Container>
            <div>
                <ul>
                    <li>
                        <h1>
                            Welcome to <span>Veles Limited SIA</span> - Your first choice for consumer electronics wholesale, distribution and trade operation services.  
                        </h1>
                    </li>
                    <li>
                        <p>
                            Veles is an established global distributor and trade operations specialist in <b>electronic consumer goods</b> including mobile phones, tablets, laptops, and much more. <b>Based in Riga</b>, Latvia, Veles specialises in supplying products from leading brands like Apple, Samsung, Sony, and HTC, as well as gaming consoles and accessories from Nintendo, Microsoft, and Sony to buyers across the globe.  
                        </p>
                    </li>
                    <li>
                        <p>
                            As a premium member of the <span>GSM Exchange community</span>, Veles is known for reliability, exceptional customer service, and expertise in the Eastern European market.
                        </p>
                    </li>
                    <li>
                        <p>
                            With direct access to manufacturers and a large, diverse inventory, Veles ensures timely deliveries and competitive prices to meet our clients’ unique needs worldwide. <b>Let’s build a lasting partnership from our very first connection!</b>
                        </p>
                    </li>
                </ul>
                
            </div>
            <div>
                <img src={mainpage} alt="main" />                
            </div>
            
        </Container>
    )

}

const Container = styled.div`
    display:flex;
    justify-content: space-between;
    padding: 5rem 10rem;
    div{
        width:45%;
        ul{
            li{
                margin-bottom:2rem;
             
            }
        }
        img{
            width:100%;
        }
    }
    @media(max-width:1024px){
        flex-direction:column;
            div{
                width:100%;
                img{
                    margin-top:3rem;
                }
            }
     }
     @media(max-width:624px){
        padding: 5rem 5rem;
     }
     @media(max-width:400px){
        padding: 5rem 1.5rem;
     }

`;

export default MainPage;
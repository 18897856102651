import React from "react";
import styled from "styled-components";
import about from "../img/about.png";

const About = () =>{

    return(
        <Container>
            <div>
                <ul>
                    <li>
                        <h1>
                            About
                        </h1>
                    </li>
                    <li>
                        <p>
                            <span>Veles Limited SIA</span> is a leading distributor and wholesaler of consumer electronics, specialising in mobile phones, tablets, laptops, and a wide range of other electronic devices. As a well-established name, and with a strong presence in the industry, we offer a huge range of products from popular brands such as <b>Apple, Samsung, Sony, and HTC</b>. In addition to mobile technology, we also supply gaming consoles and accessories from major brands like <b>Nintendo, Microsoft Xbox, and Sony PlayStation</b>.
  
                        </p>
                    </li>
                    <li>
                        <p>
                            With an excellent reputation as an exporter and trade specialist, Veles Limited SIA is also particularly recognized for distributing <b>Xiaomi</b> and <b>Meizu</b> products to the Eastern European market. We pride ourselves on being a Premium member of the <span>GSM Exchange community</span>, a status that highlights our reliability and exceptional customer service. 
                        </p>
                    </li>
                    <li>
                        <p>
                        Our headquarters and primary warehouse are strategically located in <b>Riga, Latvia</b>. This central European hub allows us to efficiently manage shipments to multiple markets, ensuring timely deliveries. Our global network of suppliers enables us to source the latest and most in-demand products at highly competitive prices, tailored to meet the diverse needs of clients worldwide.
                        </p>
                    </li>
                    <li>
                        <p>
                        Veles Limited SIA has developed a deep understanding of the specific business dynamics in Eastern Europe, particularly in trading with Russia and former Soviet countries. Our vast inventory and strong relationships with manufacturers allow us to maintain a large stock of products and fulfil urgent requests promptly.
                        </p>
                    </li>
                </ul>
                
            </div>
            <div>
                <img src={about} alt="main" />                
            </div>
            
        </Container>
    )

}

const Container = styled.div`
    display:flex;
    justify-content: space-between;
    padding: 5rem 10rem;
    div{
        width:45%;
        ul{
            li{
                margin-bottom:2rem;
             
            }
        }
        img{
            width:100%;
        }
    }
    @media(max-width:1100px){
        flex-direction:column;
            div{
                width:100%;
                img{
                    margin-top:3rem;
                }
            }
     }
     @media(max-width:624px){
        padding: 5rem 5rem;
     }
     @media(max-width:400px){
        padding: 5rem 1.5rem;
     }

`;

export default About;
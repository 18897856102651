import React from "react";
import styled from "styled-components";
import logistic from "../img/logistic.jpg";

const Logistic = () =>{

    return(
        <Container>
            <div>
                <ul>
                    <li>
                        <h1>
                            Logistics Services 
                        </h1>
                    </li>
                    <li>
                        <p>
                        Navigating the international wholesale electronics market requires a deep understanding of both the products and the logistics behind them. At Veles, we leverage our extensive knowledge of consumer electronics—from smartphones and laptops to home appliances and gaming systems —to ensure that all shipments meet the unique requirements of this fast-moving industry.

                        </p>
                    </li>
                    <li>
                        <p>
                        <b>Our team of professionals is equipped to manage every stage of the trade process, including:</b>

                        </p>
                    </li>
                    <li>
                        <p>
                        <span>Vendor Coordination:</span> We work closely with trusted suppliers to source high-demand electronics, verifying product authenticity and quality to provide peace of mind to buyers.
                        </p>
                    </li>
                    <li>
                        <p>
                         <span>Documentation and Compliance:</span> With ever-changing international regulations, we stay on top of customs laws and trade agreements to ensure all documentation is accurate, minimizing delays and avoiding penalties.
                        </p>
                    </li>
                    <li>
                        <p>
                         <span>Shipping and Freight Management:</span> Whether by air, sea, or land, we select the most efficient and cost-effective shipping solutions for your products, ensuring timely delivery and the safety of your goods.
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>Inventory and Warehousing Solutions:</span> Our global network of warehouses enables flexible inventory management, giving you the ability to store and distribute products in key regions
                        </p>
                    </li>
                </ul>
                
            </div>
            <div>
                <img src={logistic} alt="logistic" />                
            </div>
            
        </Container>
    )

}

const Container = styled.div`
    display:flex;
    justify-content: space-between;
    padding: 5rem 10rem;
    div{
        width:45%;
        ul{
            li{
                margin-bottom:2rem;
             
            }
        }
        img{
            width:100%;
        }
    }
    @media(max-width:1124px){
        flex-direction:column;
            div{
                width:100%;
                img{
                    margin-top:3rem;
                }
            }
     }
     @media(max-width:624px){
        padding: 5rem 5rem;
     }
     @media(max-width:400px){
        padding: 5rem 1.5rem;
     }

`;

export default Logistic;
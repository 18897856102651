import { createGlobalStyle } from "styled-components"
import { pallete } from "./Pallete";


const GlobalStyle = createGlobalStyle`

    *{
        margin:0;
        padding:0;
        box-sizing:border-box;
    }
    html{
        @media (max-width: 1700px){
            font-size: 75%;
        }
        @media (max-width: 1050px){
            font-size: 67%;
        }   
    }
    a,p,h1,h2,h3,h4,li{
        font-family: 'Kanit', sans-serif;
    }
    h2{
        font-size: 3rem;
    }
    h3{
        font-size:1.5rem;
    }
    h4{
        font-weight: bold;
        font-size: 2rem;    
    }
    a{
        font-size: 1.5rem;
    }
    p{
      font-size: 1.5rem;
    }
    ul{
      list-style-type: none;
    }
    span{
      color: ${pallete.LogoOrange};
    }

 
::-webkit-scrollbar-track {
  background-color:${pallete.BackGround};
}

::-moz-scrollbar-track {
background-color:${pallete.BackGround}; 
}

::-webkit-scrollbar-thumb {
  background-color: ${pallete.LogoOrange}; 
  
}

::-moz-scrollbar-thumb {
  background-color: ${pallete.LogoOrange}; 
}

::-webkit-scrollbar {
  width: 6px; 
}

::-moz-scrollbar {
  width: 6px; 
}

scrollbar-color: ${pallete.LogoOrange}  ${pallete.BackGround};
scrollbar-width: thin; 

`
export default GlobalStyle;
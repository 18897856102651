import React from "react";
import styled from "styled-components";
import products from "../img/products.png";

const Products = () =>{

    return(
        <Container>
            <div>
                <ul>
                    <li>
                        <h1>
                            Available Wholesale Products
                        </h1>
                    </li>
                    <li>
                        <p>
                        At Veles Limited SIA, we pride ourselves on offering a comprehensive selection of cutting-edge consumer electronics, catering to a wide range of needs in today's fast-paced digital world. From the latest smartphones to high-performance laptops and gaming consoles, we provide an extensive inventory of products from the most trusted and in-demand brands. 
                        </p>
                    </li>
                    <li>
                        <p>
                        Our offerings include industry leaders like <span>Apple, Samsung, Sony, and HTC</span>, ensuring our customers have access to the best technology available. Whether you're looking for the newest mobile devices, tablets, or wearables, Veles has you covered. In addition to mobile technology, we specialise in the distribution of top-tier entertainment systems and accessories. Our product range includes gaming consoles such as the <span>Sony PlayStation, Microsoft Xbox, and Nintendo Switch</span>, along with their associated peripherals.
                        </p>
                    </li>
                    <li>
                        <p>
                        Whether you're a retailer or reseller, we can help you meet the growing demand for gaming hardware, offering competitive prices and reliable service. Veles is also an experienced distributor of high-quality electronics brands like <span>Xiaomi</span> and <span>Meizu</span>, particularly for markets in Eastern Europe. We understand the importance of sourcing dependable and innovative products, and we work directly with manufacturers and authorized distributors to ensure our clients receive only authentic and high-performance goods. 

                        </p>
                    </li>
                    <li>
                        <p>
                        Our large inventory and ability to meet urgent requests make us a trusted partner in the electronics distribution space, with the flexibility to meet the unique needs of our diverse global clientele. By partnering with Veles, you gain access to a vast array of consumer electronics that are at the forefront of technology and innovation. We are committed to delivering quality, variety, and reliability, ensuring that our customers stay competitive in the ever-evolving electronics market.
                        </p>
                    </li>
                </ul>
                
            </div>
            <div>
                <img src={products} alt="products" />                
            </div>
            
        </Container>
    )

}

const Container = styled.div`
    display:flex;
    justify-content: space-between;
    padding: 5rem 10rem;
    div{
        width:45%;
        ul{
            li{
                margin-bottom:2rem;
             
            }
        }
        img{
            width:100%;
        }
    }
    @media(max-width:1024px){
        flex-direction:column;
            div{
                width:100%;
                img{
                    margin-top:3rem;
                }
            }
     }
     @media(max-width:624px){
        padding: 5rem 5rem;
     }
     @media(max-width:400px){
        padding: 5rem 1.5rem;
     }

`;

export default Products;
import React from "react";
import styled from "styled-components";
import payment from "../img/payment.jpg";

const Payment = () =>{

    return(
        <Container>
            <div>
                <ul>
                    <li>
                        <h1>
                            Secure Payment Services 
                        </h1>
                    </li>
                    <li>
                        <p>
                        When trading across borders, financial security is critical. Our secure payment services are designed to protect both buyers and sellers by providing a reliable, structured process for managing payments. With Veles, you can rest assured that your funds and products are protected every step of the way.

                        </p>
                    </li>
                    <li>
                        <p>
                        <b>Key features of our secure payment services include:</b>

                        </p>
                    </li>
                    <li>
                        <p>
                        <span>Escrow Payment Solutions:</span> We act as a trusted third party, holding funds securely until both buyer and seller have fulfilled their contractual obligations. Payments are released only when the buyer confirms receipt and satisfaction with the goods, ensuring fairness for all.
                        </p>
                    </li>
                    <li>
                        <p>
                        <span>Real-Time Payment Tracking:</span> Our service allows you to monitor the status of your payment at every stage, offering complete transparency and control.
                        </p>
                    </li>
                    <li>
                        <p>
                        <span>Multi-Currency Support:</span> Whether you’re trading in USD, EUR, or other global currencies, our payment solutions are flexible and tailored to your needs, simplifying transactions in any currency.
                        </p>
                    </li>
                    <li>
                        <p>
                        <span>Compliance and Anti-Fraud Measures:</span> Veles adheres to strict international banking standards and employs advanced fraud detection systems to prevent unauthorized transactions, protecting your financial interests.
                        </p>
                    </li>
                </ul>
                
            </div>
            <div>
                <img src={payment} alt="payment" />                
            </div>
            
        </Container>
    )

}

const Container = styled.div`
    display:flex;
    justify-content: space-between;
    padding: 5rem 10rem;
    div{
        width:45%;
        ul{
            li{
                margin-bottom:2rem;
             
            }
        }
        img{
            width:100%;
        }
    }
    @media(max-width:1124px){
        flex-direction:column;
            div{
                width:100%;
                img{
                    margin-top:3rem;
                }
            }
     }
     @media(max-width:624px){
        padding: 5rem 5rem;
     }
     @media(max-width:400px){
        padding: 5rem 1.5rem;
     }

`;

export default Payment;
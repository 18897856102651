
// Import Components
import Navigation from "./components/Navigation"
import MainPage from "./pages/MainPage";
import About from "./pages/About";
import Process from "./pages/Process";
import Products from "./pages/Products";
import Logistic from "./pages/Logistic";
import Payment from "./pages/Payment";
import Contact from "./pages/Contact";
import ScrollToTop from "./components/ScrollToTop";
// Import Styles
import GlobalStyle from "./components/GlobalStyle"
import { pallete } from "./components/Pallete";

import { Routes, Route ,useLocation } from 'react-router-dom'

function App() {
  const location = useLocation();
  return (
    <div className="App" style={{background:pallete.White }}>
      <GlobalStyle/>
      <Navigation/>
        <ScrollToTop />
        <Routes location={location} key={location.pathname}>
            <Route path="/"  element={<MainPage/>}/>
            <Route path="/about"  element={<About/>}/>
            <Route path="/process"  element={<Process/>}/>
            <Route path="/products"  element={<Products/>}/>
            <Route path="/logistic"  element={<Logistic/>}/>
            <Route path="/payment"  element={<Payment/>}/>
            <Route path="/contact" element={<Contact/>}/> 
          </Routes>
    </div>
  );
}

export default App;